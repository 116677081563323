.pong-room {
	.game-info {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.room-name {
			position: relative;
			width: 60%;
			text-align: center;

			span {
				position: absolute;
				display: flex;
				top: -2.8rem;
				z-index: 5;
				left: 50%;
				font-size: 2.4rem;
				font-weight: 700;
				min-width: fit-content;
				color: rgba(#011627, 0.3);
				transform: translate(-50%);
			}

			h2 {
				text-align: center;
				color: black;
				width: 100%;
				margin: 1rem 0;
				justify-content: center;
			}
		}

		h2,
		h1 {
			margin: 0;
		}

		h2 {
			color: #011627;
			width: 20%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		.player-two {
			color: #011627;
			justify-content: flex-start;
		}

		h1 {
			font-size: 4.8rem;
		}
	}

	.table {
		border: 0.2rem solid #fdfffc;
		background: black;
		position: relative;
		box-sizing: content-box;

		.p2-paddle,
		.p1-paddle,
		.ball,
		.divider {
			content: '';
			display: flex;
			position: absolute;
			transition: all 0.1s ease-in-out;
			box-sizing: border-box;
		}

		.p1-paddle {
			background: #fff;
		}

		.p2-paddle {
			background: #fff;
		}

		.ball {
			background: white;
			border-radius: 20px;
			transition: none;
		}

		.divider {
			height: 100%;
			content: '';
			left: calc(50% - 1px);
			border-left: 2px dashed #fdfffc;
			background: transparent;
		}
	}
}
